<template>
  <div>
    <v-autocomplete
      v-model="boatUuid"
      :items="boatOptions"
      :menu-props="{ maxHeight: '400' }"
      @input.native="loadBoats()"
      :search-input.sync="boatSearch"
      :label="label"
      color="primary"
      clearable
      autocomplete="new-password"
      :item-text="formattedBoatName"
      return-object
      :rules="rules"
      :multiple="multiple"
      :chips="chips"
      :hint="hint"
      :persistent-hint="persistentHint"
    >
    </v-autocomplete>
  </div>
</template>
<script>
import api from "@/utils/api";
import debounce from "@/plugins/debounce";

export default {
  props: {
    label: {
      type: String,
      default: "Select Boat*",
    },
    initialBoat: {
      type: Object,
      default: null,
    },
    boatUuids: {
      type: String,
      default: String,
    },
    showBoatName: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: Array,
    },
    filterByScope: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: String,
    },
    persistentHint: {
      type: Boolean,
      default: Boolean,
    },
  },

  data: () => ({
    boatOptions: [],
    boatSearch: "",
    boats: [],
  }),

  created() {
    let params = false;

    if (
      typeof this.filterByScope !== "undefined" &&
      Object.keys(this.filterByScope).length !== 0
    ) {
      params = true;
    }

    this.loadBoats(params).then((resp) => {
      let boat = null;
      if (this.initialBoat) {
        boat = this.boatOptions.find((element) => {
          return element.uuid === this.initialBoat.uuid;
        });
        this.boatUuid = boat;
      }
    });
  },

  watch: {
    filterByScope: {
      handler(newValue, oldValue) {
        this.loadBoats(true);
      },
      deep: true,
    },
  },

  computed: {
    boatUuid: {
      get: function() {
        return this.initialBoat;
      },
      set: function(value) {
        if (typeof value !== "undefined") {
          this.$emit("change", value);
          this.boats.push(value);
        } else {
          this.$emit("change", null);
        }
      },
    },
  },

  methods: {
    formattedBoatName(item) {
      if (item && !this.showBoatName) {
        return `${item.model.brand_name} ${item.model.model} #${item.slot_number}`;
      } else if (this.showBoatName) {
        return item.boat_name;
      } else {
        return;
      }
    },

    removeBoat(item) {
      let index = this.boats.findIndex((boat) => boat.uuid === item.uuid);
      this.boats.splice(index, 1);
    },

    loadBoats(partnerSpecific = false) {
      if (partnerSpecific) {
        return new Promise((resolve, reject) => {
          api.getCrmPartnerBoats(this.filterByScope.uuid).then((resp) => {
            this.boatOptions = resp.data;
            resolve();
          });
        });
      }

      return new Promise((resolve, reject) => {
        this.boatSearch = this.boatSearch ? this.boatSearch : "";
        api
          .boatsAutocomplete({
            search: this.boatSearch,
            autocomplete: true,
          })
          .then((resp) => {
            resp.data.results.forEach((element) => {
              this.boatOptions.push(element);
            });
            resolve();
          });
      });
    },
  },
};
</script>
