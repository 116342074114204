<template>
  <div>
    <v-container my-10>
      <v-layout align-center justify-center>
        <v-row v-if="loading">
          <v-progress-circular
            :size="55"
            :width="4"
            indeterminate
            class="mt-12 mx-auto"
            color="primary"
          ></v-progress-circular>
        </v-row>

        <v-form ref="form" v-model="valid" lazy-validation v-if="!loading">
          <v-row class="mb-2">
            <v-col cols="12">
              <span> General </span>
            </v-col>
          </v-row>

          <v-card v-if="!loading" width="650px" class="mb-12">
            <v-card-text class="pb-0 pb-6">
              <v-row class="mx-2">
                <v-col cols="12" class="mt-2">
                  <v-text-field
                    v-model="editedItem.title"
                    label="Title*"
                    required
                    :rules="titleRule"
                    hint="Required"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mx-2 mt-1" v-if="scopeOptions.length > 1">
                <v-col cols="12">
                  <v-select
                    label="Location*"
                    v-model="editedItem.scope"
                    :items="scopeOptions"
                    item-text="name"
                    item-value="uuid"
                    persistent-hint
                    :rules="scopeRule"
                    hint="Required"
                    @change="handleScopeChange"
                    return-object
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mx-2 mb-2">
                <v-col cols="12">
                  <boat-selector
                    :initial-boat="editedItem.boat"
                    :show-boat-name="true"
                    label="Boats*"
                    @change="handleBoatChange"
                    :filter-by-scope="dealership"
                    :multiple="true"
                    :chips="true"
                    hint="Required"
                    :persistent-hint="true"
                  ></boat-selector>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row class="mb-2">
            <v-col cols="12">
              <span> Automation rule</span>
            </v-col>
          </v-row>

          <v-card v-if="!loading" width="650px">
            <v-card-text class="pb-0">
              <v-row class="mx-2 mt-2">
                <v-col cols="12" class="mt-2 mb-2">
                  <span>
                    <!-- Affect automation: -->
                    Select the event which will trigger this automation rule:
                  </span>
                  <v-chip-group
                    class="mt-2"
                    active-class="primary--text"
                    column
                    v-model="whenSelection"
                    mandatory
                  >
                    <v-chip
                      v-for="tag in whenTagsOptions"
                      :key="tag.id"
                      :value="tag.id"
                    >
                      {{ tag.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row class="mx-2 mt-2">
                <v-col cols="12">
                  <span>
                    <!-- After action triggers: -->
                    Select a type of event that should be triggered:
                  </span>
                  <v-chip-group
                    class="mt-2"
                    active-class="primary--text"
                    column
                    v-model="thenSelection"
                    @change="changeThenSelection"
                    mandatory
                  >
                    <v-chip
                      v-for="tag in thenTagsOptions"
                      :key="tag.id"
                      :value="tag.id"
                    >
                      {{ tag.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row class="mx-2 mt-4">
                <v-col cols="12">
                  <span>
                    Select a target audience for the event
                  </span>
                  <v-chip-group
                    class="mt-2"
                    active-class="primary--text"
                    column
                    v-model="forSelection"
                    mandatory
                  >
                    <v-chip
                      v-for="tag in forTagsOptions"
                      :key="tag.id"
                      :value="tag.id"
                    >
                      {{ tag.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>

              <v-row no-gutters class="mx-5 mb-8">
                <v-col cols="12">
                  <div v-if="forSelection === 'other'">
                    <v-text-field
                      class="pt-0 mt-3"
                      v-model="editedItem.email"
                      label="E-mail address*"
                      required
                      :rules="emailRule"
                      hint="Required"
                      persistent-hint
                      autocomplete="false"
                    ></v-text-field>
                  </div>
                  <div v-if="forSelection === 'teamMember'">
                    <task-assignee-selector
                      :assignee-uuid.sync="editedItem.assignee_email"
                      :initial-data="{}"
                      :professional="dealership.uuid"
                      @change="handleAssignee"
                      :key="forceUpdateTaskAssigneeSelector"
                      :filter-by-scope="scope"
                      :multiple="true"
                    ></task-assignee-selector>
                  </div>
                  <div v-if="forSelection === 'guest'"></div>
                </v-col>
              </v-row>

              <v-row class="mx-2 mb-12">
                <v-col cols="12" class="mb-6">
                  <span>
                    Suggested checklists:
                  </span>

                  <checklist-picker
                    :initial-uuids="editedItem.checklists"
                    :filter-by="appliedChecklistFilter"
                    :key="forceUpdateChecklistPicker"
                  ></checklist-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-layout>
    </v-container>
    <!-- TODO: Refactor this chunck of code, lots of hacking -->
    <v-app-bar
      color="white"
      bottom
      style="right:unset;
      left:unset;
      z-index:3;
      position:fixed;
      width:100%;
      left: 130px;
      right: 0;"
    >
      <v-layout align-center justify-center>
        <v-card flat width="650">
          <v-card-actions>
            <v-btn color="primary" text @click="close()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-app-bar>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import snackbar from "@/utils/snackbar";
import { mapGetters, mapState } from "vuex";
import BoatSelector from "@/components/tasks/BoatSelector.vue";
import TaskAssigneeSelector from "@/components/tasks/TaskAssigneeSelector";
import ChecklistPicker from "@/components/pickers/ChecklistPicker";

export default {
  components: {
    BoatSelector,
    TaskAssigneeSelector,
    ChecklistPicker,
  },
  data: () => ({
    editView: false,
    loading: true,
    valid: true,
    scope: {},
    scopeRule: [(v) => !!v || "Scope is required"],
    titleRule: [(v) => !!v || "Title is required"],
    emailRule: [(v) => !!v || "E-mail address is required"],
    whenTagsOptions: [
      { name: "Before departure", id: "beforeDeparture" },
      { name: "After return", id: "afterReturnToBase" },
    ],
    whenSelection: "beforeDeparture",
    thenTagsOptions: [
      { name: "Onboarding", id: "onboarding", filter: "GUEST_CHECKIN" },
      { name: "Offboarding", id: "offboarding", filter: "GUEST_CHECKOUT" },
      { name: "Protocol", id: "protocol", filter: null },
      { name: "Maintenance", id: "maintenance", filter: null },
      { name: "Regular inspection ", id: "regular_inspection", filter: null },
    ],
    thenSelection: "onboarding",
    appliedChecklistFilter: "GUEST_CHECKIN",
    forTagsOptions: [
      { name: "Guest", id: "guest" },
      { name: "Team member", id: "teamMember" },
      { name: "Other", id: "other" },
      { name: "Decide later", id: "decideLater" },
    ],
    forSelection: "guest",
    editedItem: {
      title: "",
      assignee_email: null,
      assignee: null,
      crm_partner_uuid: null,
      boat: null,
      checklists: [],
    },
    forceUpdateTaskAssigneeSelector: 0,
    forceUpdateChecklistPicker: 0,
  }),

  computed: {
    ...mapGetters(["getMe"]),
    scopeOptions() {
      return this.getMe.crm_groups;
    },
  },

  created() {
    this.getView();

    if (this.editView) {
      this.loadAutomation();
      this.scope =
        this.getMe.crm_groups.length !== 0 ? this.getMe.crm_groups[0] : null;

      const scope = this.scopeOptions.find((item) => {
        return item.uuid === "407193584eeb422a988c8750b1c16550";
      });

      this.$set(this.editedItem, "name", "Guest Onboarding");
      this.editedItem = {
        title: "Guest Onboarding",
        scope: scope,
        boat: {
          uuid: "83452be14f4e4dd7a8aaf6a595d7a04a",
          model: {
            uuid: "230d66f60b7f4953a8535bb5338fc42a",
            brand_name: "Hanse",
            model: "41",
          },
          boat_name: "Biopsea",
          pretty_name: "41",
          device_serial: "Test Device 7855",
          slot_number: null,
          ce_number: null,
          is_user_present: true,
        },
        whenSelection: "beforeDeparture",
        thenSelection: "onboarding",
        forSelection: "guest",
        checklists: ["32eab330d9a94968b2b88194ec9f8b50"],
        email: "",
      };

      this.handleScopeChange(scope);
    } else {
      this.scope =
        this.getMe.crm_groups.length !== 0 ? this.getMe.crm_groups[0] : null;

      if (this.scope !== null) {
        this.setDealershipFromScope(this.scope);
      }

      this.loading = false;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.getView();
    },
  },

  methods: {
    handleAssignee(event) {},
    changeThenSelection(event) {
      this.appliedChecklistFilter = this.thenTagsOptions.find((item) => {
        return item.id === event;
      }).filter;
      this.forceUpdateChecklistPicker++;
      this.editedItem.checklists = [];
    },

    setDealershipFromScope(scope, ev) {
      this.dealership = this.getMe.dealerships.find((item) => {
        return item.partner_group.uuid === scope.uuid;
      });
    },
    handleScopeChange(event) {
      this.scope = event;
      this.setDealershipFromScope(event, 1);
      this.forceUpdateTaskAssigneeSelector++;
    },

    getView() {
      this.editView = this.$route.name === "AutomationsEdit";
    },

    async loadAutomation() {
      const data = await api.getAutomation(this.$route.params.id);
      this.scope = data;
      // this.setDealershipFromScope(this.scope);
      // this.editedItem = data;
      this.loading = false;
    },

    close() {
      this.$router.push({
        name: "Automations",
      });
    },

    handleBoatChange(boat) {
      if (boat === null) {
        this.crm_boat_uuid = null;
        return;
      }
      this.crm_boat_uuid = boat.uuid;
    },

    saveAutomations() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.editedItem.crm_group_uuid = this.scope.uuid;

        if (this.editView) {
          api
            .updateAutomation(this.editedItem.uuid, this.editedItem, false)
            .then((resp) => {
              snackbar.success("Successfully saved");
            })
            .catch((err) => {
              this.handleError(err.response);
              this.loading = false;
            });
        } else {
          api
            .createAutomation(this.editedItem, false)
            .then((resp) => {
              snackbar.success("Successfully saved");
            })
            .catch((err) => {
              this.handleError(err.response);
              this.loading = false;
            });
        }
      } else {
        snackbar.error("Please fill required fields");
      }
    },

    handleError(err) {
      if (err) {
        snackbar.error(err.response);
      } else {
        snackbar.error();
      }
    },
  },
};
</script>
<style scoped></style>
