<template>
  <v-autocomplete
    label="Checklist"
    v-model="checklist"
    :items="checklistOptions"
    :menu-props="{ maxHeight: '400' }"
    @input.native="loadChecklists($event, $data)"
    :search-input.sync="checklistSearch"
    color="primary"
    item-text="name"
    item-value="uuid"
    autocomplete="new-password"
    no-filter
    multiple
    @change="handleChange"
    clearable
  >
  </v-autocomplete>
</template>

<script>
import api from "@/utils/api";

export default {
  props: {
    initialUuids: {
      type: Array,
      default: Array,
    },
    filterBy: {
      type: String,
      default: String,
    },
  },
  data: () => ({
    checklistOptions: [],
    checklistSearch: "",
    timeout: null,
    checklist: [],
  }),
  created() {
    this.loadChecklists(true);
  },

  methods: {
    handleChange(value = {}) {
      this.$emit("change", value);
    },

    loadChecklists(initalLoad = false) {
      let fetchChecklists = async () => {
        const { data } = await api.getTemplateList();

        this.checklistOptions = data.results
          .map((item) => {
            return {
              name: item.checklists[0].name,
              uuid: item.uuid,
              type: item.checklists[0].checklist_type,
            };
          })
          .filter((item) => {
            if (
              (this.filterBy === "maintenance" ||
                this.filterBy === "regular_inspection") &&
              item.type === null
            ) {
              return true;
            }
            return item.type === this.filterBy;
          });

        if (this.initialUuids.length !== 0) {
          this.checklist = this.checklistOptions.filter((item) => {
            return this.initialUuids.includes(item.uuid);
          });
        }
      };

      if (initalLoad) return fetchChecklists();

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        return fetchChecklists();
      }, 100);
    },
  },
};
</script>
