<template>
  <div>
    <!-- @change="handleUnrecognizedAssignee" -->
    <v-autocomplete
      :class="{ 'pt-0': noStyle, 'mt-0': noStyle }"
      :label="'Assignee'"
      v-model="assigneeUsername"
      :items="assigneeOptions"
      item-value="username"
      item-text="username"
      :menu-props="{ maxHeight: '400' }"
      @input.native="loadAssignees($event, $data)"
      :search-input.sync="assigneeSearch"
      persistent-hint
      color="primary"
      clearable
      autocomplete="new-password"
      :disabled="disabled"
      :multiple="multiple"
    ></v-autocomplete>
    <!-- <v-row no-gutters v-if="assigneeUsername === 'Other'">
      <template>
        <v-text-field
          class="mt-0 pt-0"
          v-model="unrecognizedAssignee"
          color="primary"
          label="Enter E-mail address"
        />
      </template>
    </v-row> -->
  </div>
</template>

<script>
import api from "@/utils/api";
import debounce from "@/plugins/debounce";

export default {
  props: [
    "assigneeUuid",
    "initialData",
    "noStyle",
    "professional",
    "disabled",
    "filterByScope",
    "multiple",
  ],
  data: () => ({
    assigneeOptions: [],
    assigneeSearch: "",
    // unrecognizedAssignee: "",
  }),
  created() {
    if (Object.keys(this.initialData).length !== 0) {
      this.initialData.username = this.initialData.email;
      this.assigneeOptions.push(this.initialData);
      this.assigneeUsername = this.initialData.username;
    }
    this.loadAssignees(null, this);
  },
  // watch: {
  //   unrecognizedAssignee(newValue, oldValue) {
  //     this.$emit("unrecognizedAssignee", this.unrecognizedAssignee);
  //   },
  // },
  computed: {
    assigneeUsername: {
      get: function() {
        return this.assigneeUuid;
      },
      set: function(value) {
        if (typeof value !== "undefined") {
          this.$emit("update:assigneeUuid", value);
          this.$emit("change", value);
        } else {
          this.$emit("update:assigneeUuid", null);
          this.$emit("change", null);
        }
      },
    },
  },

  watch: {
    filterByScope(newVal, oldVal) {
      this.assigneeOptions = [];
      this.assigneeSearch = "";
      this.assigneeUsername = null;
    },
  },

  methods: {
    // handleUnrecognizedAssignee(event) {
    //   if (event !== "Other") {
    //     this.unrecognizedAssignee = "";
    //   }
    // },
    loadAssignees: function() {
      var self = this;
      self.assigneeSearch = self.assigneeSearch ? self.assigneeSearch : "";
      if (typeof this.professional !== "undefined") {
        api
          .taskAssigneeAutocomplete(self.professional, self.assigneeSearch)
          .then((resp) => {
            resp.data.results.forEach((element) => {
              if (
                !self.assigneeOptions.find(
                  (el) => el.username == element.username
                )
              ) {
                self.assigneeOptions.push(element);
              }
            });
          });
      }
    },
    debounceDealers: debounce((event, self) => {
      this.loadAssignees();
    }, 300),
  },
};
</script>
